/**
 * include dependency of axios request
 * 
 * @AxiosInstance
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
import AxiosInstance from './Index'

/**
 * include dependency of API request endpoint
 * 
 * @EndPoints
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
import EndPoints from '../endpoints/Endpoints'
 
/**
 * include dependency of API request endpoint
 * 
 * @log
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
import { log } from '../../helpers/ConsoleLog'
 
/**
 * include dependency of API request response
 * 
 * @Response
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
import { SuccessResponse, ErrorResponse } from '../../helpers/Response'
  
/**
 * create a new tv channel
 * 
 * @channelCreate
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
export const flagCreate = async (req) => {
    // init with error message
    const error_response = {
        status: 'fail',
        msg: 'Something gone wrong.',
        data: []
    }
 
    try {
        // init params object
        const params = new URLSearchParams()
        
        // add params data
        params.append('date', req.date)
        params.append('name', req.name)

        const data = await AxiosInstance.post(EndPoints.flag_create, params)
            .then(function (data) {
                return SuccessResponse(data)
            })
            .catch(function (err) {
                log('error', `Error due to : ${err.message}`)
                return ErrorResponse(err)
            })
        return data
    } catch(err) {
        return error_response
    }
}
 
/**
 * fetch flag list
 * 
 * @flagList
 * @created_at  31st May 2022
 * @created_by  Muhammad Hasan
 */
export const flagList = async () => {
    try {
        const data = await AxiosInstance.post(EndPoints.flag_list)
                    .then((data) => {
                        return SuccessResponse(data)
                    })
                    .catch((err) => {
                        log('error', `Error occured on when fetch flag, due to: ${err}`)
                        return ErrorResponse(err)
                    })
        return data
    } catch (err) {
        log('error', `Error occured on when fetch flag, due to: ${err}`)
        return false
    }
}
 
/**
 * search flag by name
 * 
 * @flagSearch
 * @created_at  31st May 2022
 * @created_by  Muhammad Hasan
 */
export const flagSearch = async (query) => {
    try {
        // init params object
        const params = new URLSearchParams()
        
        // add params data
        params.append('search_q', query)

        const data = await AxiosInstance.post(EndPoints.flag_search, params)
                    .then((data) => {
                        return SuccessResponse(data)
                    })
                    .catch((err) => {
                        log('error', `Error occured on when search flag, due to: ${err}`)
                        return ErrorResponse(err)
                    })
        return data
    } catch (err) {
        log('error', `Error occured on when search flag, due to: ${err}`)
        return []
    }
}

/**
 * fetch video urls or details of a single flag
 * 
 * @getFlagDetails
 * @created_at  1st June 2022
 * @created_by  Muhammad Hasan
 */
export const getFlagDetails = async (id) => {
    // init with error message
    const error_response = {
        status: 'fail',
        msg: 'Something gone wrong.',
        data: []
    }
 
    try {
        // init params object
        const params = new URLSearchParams()
        
        // add params data
        params.append('flag_id', id)

        const data = await AxiosInstance.post(EndPoints.flag_details, params)
            .then(function (data) {
                return SuccessResponse(data)
            })
            .catch(function (err) {
                log('error', `Error due to : ${err.message}`)
                return ErrorResponse(err)
            })
        return data
    } catch(err) {
        return error_response
    }
}


/**
 * remove video from the server and db
 * 
 * @removeVideoFromFlag
 * @created_at  1st June 2022
 * @created_by  Muhammad Hasan
 */
export const removeVideoFromFlag = async (form_data) => {
    // init with error message
    const error_response = {
        status: 'fail',
        msg: 'Something gone wrong.',
        data: []
    }

    try {
        // init params object
        const params = new URLSearchParams()
        
        // add params data
        params.append('video_id', form_data.url)
        params.append('flag_id', form_data.flag)

        const data = await AxiosInstance.post(EndPoints.flag_video_remove, params)
            .then(function (data) {
                return SuccessResponse(data)
            })
            .catch(function (err) {
                log('error', `Error due to : ${err.message}`)
                return ErrorResponse(err)
            })
        return data
    } catch (err) {
        return error_response
    }
}