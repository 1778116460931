import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

/**
 * generate page breadcrumb
 * 
 * @Bradecrumb
 * @created_at  19th May 2022
 * @created_by  Muhammad Hasan
 */
function Bradecrumb({item}){
    // create Breadcrumb name
    const name = item.charAt(0).toUpperCase() + item.slice(1);
    
    return(
        <Breadcrumb>
            <Breadcrumb.Item href="/admin">
                <HomeOutlined />
            </Breadcrumb.Item>
                <Breadcrumb.Item href={`/admin/${item}`}>
                <span>{name} List</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                {name}
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default Bradecrumb;