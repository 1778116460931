/**
 * get current date time as a string
 * @method
 * @name currentDateTime
 * @param   {null} 
 * @returns  {string} current date time
 * @created_by  Muhammad Hasan
 * @created_at  26th April 2022
 */
export const currentDateTime = () => {
    let currentDateTime = new Date();
    return  currentDateTime.getDate()+'/'+(currentDateTime.getMonth()+1)+'/'+currentDateTime.getFullYear()+' '+
            currentDateTime.getHours()+':'+currentDateTime.getMinutes()+':'+currentDateTime.getSeconds();
}


/**
 * this method will return current date time as a unix timestamp format
 * @method
 * @name currentDateTime_Unix
 * @param   {null} 
 * @returns  {number} current date time as a timestamp format
 * @created_by  Muhammad Hasan
 * @created_at  26th April 2022
 */
export const currentDateTime_Unix = () => {
    return Date.now();
}


/**
 * this method will return current date time as a unix timestamp seconds format
 * @method
 * @name currentDateTime_ToSecond
 * @param   {null} 
 * @returns  {number} current date time as a timestamp(second) format 
 * @created_by  Muhammad Hasan
 * @created_at  26th April 2022
 */
export const currentDateTime_ToSecond = () => {
    return Math.floor(new Date().getTime() / 1000)
}


/**
 * This method will convert human readable date time to unix datetime
 * Ex. :  Input : 12/31/2022 23:59:59 (Month/Date/Year Hours:Minutes:Seconds)
 * Output: 1672509599 (UNIX TimeStamp)
 * @method
 * @name givenDateTimeToUnix
 * @param   {string} dateTime - date time as a string format
 * @returns  {int} unix_timestamp
 * @created_by  Muhammad Hasan
 * @created_at  26th April 2022
 * @desc    
 */
export const givenDateTimeToUnix = (dateTime) => {
    return Date.parse(dateTime)/1000;
}

/**
 * get date time as a string/readable format
 * @method
 * @name ReadableDateTime
 * @param {number} timestamp - date time as a timestamp format
 * @returns {string} get human readable date time
 * @created_at  26th April 2022
 * @created_by  Muhammad Hasan
 */
export const ReadableDateTime = (timestamp) => {
    // convert timestamp to milliseconds timestamp
    let date = new Date(timestamp * 1000)
    return date.toLocaleString()
}
  