import React from 'react';
import { 
    Row, 
    Col, 
    Form, 
    Input, 
    Button, 
    Spin, 
    message 
} from 'antd';
import { login } from '../../../api/request/Admin';
import { SetAdminCookies } from '../../../helpers/Cookies';

/**
 * login component of video control panel
 * 
 * @Login
 * @created_at  18th May 2022
 * @created_by  Muhammad Hasan
 */
const Login = () => {
    // set page title
    document.title="Streaminig - login";

    // state of loading indication
    const [loading, setLoading] = React.useState(false)

    // login submit button pressed
    const onFinish = async (values) => {
        // start page loading
        setLoading(true)

        // request for admin login
        const result = await login(values)

        // if loggedin information true
        if(result.status === 'Success'){
            setTimeout(() => {
                setLoading(false)
                message.success(result.message, 4)
            }, 1000)

            // create admin user object
            const admin_user = {
                id: result.data.user._id,
                aid: result.data.user.admin_id,
                email: result.data.user.email,
                access: result.data.user.access,
                token: result.data.token,
            }

            // set admin cookies
            SetAdminCookies('stream_adata', JSON.stringify(admin_user))

            // redirect to dashboard after 2 seconds
            setTimeout(() => {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}admin`)
            }, 2000)
        } else {
            setTimeout(() => {
                setLoading(false)
                message.error(result.message)
            }, 1000)
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const style = {
        marginTop: "10rem"
    }

    const center = {
        textAlign: "center",
    }

    return (
        <Spin spinning={loading} delay={500}>
            <Row justify="space-between" align="middle">
                <Col span={6} offset={8} style={center}>
                    <h2 style={style}>
                        Video Portal Login
                    </h2>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: "Email can't be empty",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Password can't be empty",
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Spin>
    );
};

export default Login;