import Cookies from 'js-cookie'

/**
 * get date time as a string/readable format
 * 
 * @SetAdminData
 * @param {object} [data] Optionaltimestamp - date time as a timestamp format
 * @returns {string} get human readable date time
 * @created_by  Muhammad Hasan
 * @created_at  19th May 2022
 */
export const SetAdminCookies = (key, value) => {
    // set admin cookies value with key
    Cookies.set(key, value, { expires: 1, path: '' })
    return true
}

export const FetchAdminCookies = (key) => {
    // convert timestamp to milliseconds timestamp
    return Cookies.get(key)
}


/**
 * logout admin from the dashboard
 * 
 * @clearAdminCookies
 * @param 
 * @returns 
 * @created_by  Muhammad Hasan
 * @created_at  19th May 2022
 */
export const clearAdminCookies = (key) => {
    Cookies.remove(key)
    return true
}

/**
 * get date time as a string/readable format
 * 
 * @getAdminToken
 * @param 
 * @returns {string} get admin logged in token
 * @created_by  Muhammad Hasan
 * @created_at  19th May 2022
 */
export const getAdminToken = () => {
    if(Cookies.get('adata')){
        let data = JSON.parse(Cookies.get('adata'))
        return (data.token) ? data.token : null
    } else {
        return null
    }
}
  