import React from 'react';
import { Table, Tag, Modal, Spin, Row, Col, Button, message } from 'antd';
import { AppstoreAddOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import { 
    programList, 
    programDelete,
    programUpdate
} from "../../../api/request/Program";
import { ReadableDateTime } from "../../../helpers/DateTime";
import Bradecrumb from "../../components/bradecrumb/Bradecrumb";
import Divider from "../../components/divider/Divider";
import { Link } from "react-router-dom"

/**
 * display program list
 * 
 * @ProgramList
 * @created_at  07th May 2022
 * @created_by  Muhammad Hasan
 */
function ProgramList(){
    // state of loading indication
    const [loading, setLoading] = React.useState(false)
    
    // channel list
    const [channelData, setChannelData] = React.useState([])
    
    const { confirm } = Modal
    
    const key = 'updatable'

    // request for delete channel
    const deleteChannel = async (id) => {
        confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            async onOk() {
                // show loading indication
                message.loading({ content: 'Channel Deleting...', key})
                
                // display page loading
                setLoading(true)

                // make an api request to delete channel
                const result = await programDelete(id)

                if(result){
                    setTimeout(() => {
                        if(result.status === 'success'){
                            // display success message
                            message.success({content: result.message, key, duration: 2})
                            
                            // remove channel from the list
                            const updated_list = channelData.filter((data) => {
                                if(data.cid != id){
                                    return data
                                }
                            })
                            
                            // update channel list
                            setChannelData(updated_list)
                        } 
                        else {
                            // display error message
                            message.error({content: result.message, key, duration: 2})
                        }
                        // hide page loading
                        setLoading(false)
                    }, 1000)
                }
            },
            onCancel() {},
        });
    }

    // request for edit program
    const updateChannel = async (id) => {
        const result = await programUpdate(id)
    }

    // make seconds to hour, minutes and seconds format
    const secondToHourMinutes = (value) => {
        var date = new Date(null);
        date.setSeconds(value);
        return date.toISOString().substr(11, 8);
    }

    const manageLink = (value) => {
        return <Link to={'home'}>value</Link>
    }

    React.useEffect(() => {
        const fetchChannelList = async () => {
            // start page loading
            setLoading(true)

            // fetch channel list
            const result = await programList()
            
            if(result) {
                // stop page lading indication
                setTimeout(() => {
                    setLoading(false)
                }, 800)
                
                // set channel list
                setChannelData(result.data)
            }
        }
        fetchChannelList()
    }, [])

    const columns = [
        {
          title: 'Id',
          dataIndex: 'pid',
          key: 'pid',
          width: 50,
        },
        {
            title: 'Program Name',
            dataIndex: 'program_name',
            key: 'program_name',
            render: (value, record) => (
                <>
                    <Link to={`/admin/program-manage/${record.pid}`}>
                        {value}
                    </Link>
                </>
            ),
            width: 150,
        },
        {
            title: 'Channel',
            dataIndex: 'channel_name',
            key: 'channel_name',
            width: 100,
        },
        {
            title: 'On Air',
            dataIndex: 'on_air',
            key: 'on_air',
            render: value => <span>{ReadableDateTime(value)}</span>,
            width: 150,
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: value => <span>{secondToHourMinutes(value)}</span>,
            width: 100,
        },
        {
          title: 'Created At',
          dataIndex: 'created_at',
          key: 'created_at',
          render: value => <span>{ReadableDateTime(value)}</span>,
          width: 150,
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, data) => (
            <span>
                <Tag color="red" key={`remove_${data.cid}`} onClick={()=>{deleteChannel(data.cid)}}>
                    Delete
                </Tag>
                <Tag color="green" key={`edit_${data.cid}`} onClick={()=>{updateChannel(data.cid)}}>
                    Edit
                </Tag>
            </span>
          ),
          width: 150,
        }
    ];

    return(
        <>
            <Spin spinning={loading} delay={500}>

                <Bradecrumb item="program" />
                
                <Divider type="" text="Program List" position="left" />

                <Row>
                    <Col span="24" offset="0">
                        <Link to={"/admin/program"}>
                            <Button type="primary" icon={<AppstoreAddOutlined/>}>
                                Add New Program
                            </Button>
                        </Link>
                    </Col>
                </Row>
                
                <Row>
                    <Col span={24} offset={0}>
                        <Table columns={columns} dataSource={channelData} />
                    </Col>
                </Row>

            </Spin>
        </>
    )
}

export default ProgramList;