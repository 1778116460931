import React from 'react';
import Cookies from 'universal-cookie';
import { BrowserRouter as Router } from "react-router-dom";
import { Layout, Menu } from 'antd';
import RouterMenu from '../../routes/RouterMenu';
import AllRouter from '../../routes/Router';
import Login from '../../pages/containers/Login/Login';

const { Header, Content, Sider } = Layout;
const cookies = new Cookies();

function Index(){
    let auth_status;
    
    // get loggedin admin data
    const session_data = cookies.get('stream_adata')

    // set true, if get cookies info & client details
    if(session_data){
        if( session_data.id && 
            session_data.aid &&
            session_data.email &&
            session_data.token )
        {
            auth_status = true
        } else {
            auth_status = false
        }
    }else{
        auth_status = false
    }

    // innit auth variable
    const [auth, setAuth] = React.useState(auth_status)

    const authControl = () => setAuth(auth ? true : false)

    if(auth) {
        return (
            <Router>
                <Header className="header">
                    <span style={{color:"white", fontWeight: "100", fontSize:"1.25rem"}}>
                        Video Streaming Control Panel
                    </span>
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}/>
                </Header>
                
                <Layout>
                    <Sider width={200} className="site-layout-background">
                        <RouterMenu />
                    </Sider>

                    <Layout style={{padding: '0 24px 24px'}}>
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        >
                            <AllRouter/>
                        </Content>
                    </Layout>
                </Layout>
            </Router>
        );
    } else {
        return(<Login />)
    }
}

export default Index;