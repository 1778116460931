import IndexLayout from './apps/pages/layout/Index'
import './App.css'

function App() {
  return (
    <>
      <IndexLayout />
    </>
  );
}

export default App;
