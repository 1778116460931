import React from 'react';
import { 
    Form, 
    Input, 
    Button, 
    Row, 
    Col, 
    Breadcrumb,
    Divider,
    Spin,
    DatePicker,
    message 
} from 'antd';
import { 
    HomeOutlined, 
    UserOutlined, 
    UploadOutlined 
} from '@ant-design/icons';
import text from "../../../helpers/constant/Flag";
import { flagCreate } from "../../../api/request/Flag";
import Bradecrumb from "../../components/bradecrumb/Bradecrumb";

/**
 * flag added form
 * 
 * @FlagAdd
 * @created_at  30th May 2022
 * @created_by  Muhammad Hasan
 */
function FlagAdd(){
    // state of loading indication
    const [loading, setLoading] = React.useState(false)

    // executed when form submit
    const onFinish = async (values) => {
        // start page loading
        setLoading(true)
        
        // generate video form data
        const form_data = {
            date: values.flag_date,
            name: values.flag_name,
        }

        // api request for create video flag
        const result = await flagCreate(form_data)        

        // if gets any wrong
        if(result.status === 'success'){
            setTimeout(() => {
                setLoading(false)
                message.success(result.message)

            }, 1000)
        } else {
            setTimeout(() => {
                setLoading(false)
                message.error(result.message);
            }, 1000)
        }
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return(
        <>
            <Spin spinning={loading} delay={500}>
                
                <Bradecrumb item="flag"/>
                
                <Divider orientation="left">
                    {text.add_page.title}
                </Divider>

                <Row>
                    <Col span={12} offset={0}>
                        <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ remember: true }}    
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >


                            <Form.Item 
                                label="Select Date"
                                name="flag_date"
                                rules={[
                                    {
                                        required: true,
                                        message: `${text.error.empty_date}`
                                    }
                                ]}
                            >
                                <DatePicker />
                            </Form.Item>

                            {/* flag title */}
                            <Form.Item
                                label={text.add_page.label_flag}
                                name="flag_name"
                                rules={[
                                    {
                                        required: true,
                                        message: `${text.error.empty_flag}`,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            
                            {/* submit button */}
                            <Form.Item wrapperCol={{offset: 8, span: 16,}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>

            </Spin>
        </>
    )
}

export default FlagAdd;