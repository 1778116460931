import React from 'react';
import { 
    Form, 
    Input, 
    Button, 
    Row, 
    Col, 
    Divider, 
    InputNumber, 
    DatePicker, 
    Select, 
    Spin,
    message } from 'antd';
import {
    channelSearch,
} from "../../../api/request/Channel";
import { 
    programCreate 
} from "../../../api/request/Program";    
import Bradecrumb from "../../components/bradecrumb/Bradecrumb";
import debounce from 'lodash/debounce';


// generate select channel list searchable
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
      <Select
        showSearch
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    );
}

// search channel by channel name
async function fetchUserList(search_q) {
    return await channelSearch(search_q)
            .then((res) => res.data)
            .then((body) => 
                body.map((list) => ({
                    label: list.channel_name,
                    value: list.cid
                }))
            )
}

/**
 * added a program to the database
 * 
 * @ProgramInputs
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
function ProgramInputs(){
    // state of loading indication
    const [loading, setLoading] = React.useState(false)
    
    const [optionValue, setOptionValue] = React.useState([]);

    const onFinish = async (values) => {
        // start page loading
        setLoading(true)

        // api request for create a new tv program 
        const result = await programCreate(values)

        // if gets any wrong
        if(result.status === 'success'){
            setTimeout(() => {
                setLoading(false)
                message.success(result.message)
            }, 1000)
        } else {
            setTimeout(() => {
                setLoading(false)
                message.error(result.message);
            }, 1000)
        }
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return(
        <>
            <Spin spinning={loading} delay={500}>
                
                <Bradecrumb item="program" />
                
                <Divider orientation="left">Add New Program</Divider>

                <Row>
                    <Col span={12} offset={0}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}    
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        {/* program name */}
                        <Form.Item
                            label="Program Name"
                            name="program_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please write program name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        {/* program duration */}
                        <Form.Item
                            label="Program Duration"
                            rules={[
                                { required: false }
                            ]}
                        >   
                            <Form.Item
                                name="hour"
                                rules={[{ required: true }]}
                                style={{ display: 'inline-block' }}
                            >
                                <InputNumber status="warning" min="0" placeholder="Hour"/>
                            </Form.Item>
                            <Form.Item
                                name="min"
                                rules={[{ required: true }]}
                                style={{ display: 'inline-block' }}
                            >
                                <InputNumber status="warning" min="0" placeholder="Minutes"/>
                            </Form.Item>
                            <Form.Item
                                name="seconds"
                                rules={[{ required: true }]}
                                style={{ display: 'inline-block' }}
                            >
                                <InputNumber status="warning" min="0" placeholder="Seconds"/>
                            </Form.Item>
                        </Form.Item>


                        {/* program on air time */}
                        <Form.Item
                            label="Program On Air"
                            name="on_air"
                            rules={[
                                { 
                                    required: false,
                                    message: 'Have to select on air!'
                                }
                            ]}
                        >   
                            <DatePicker showTime />
                        </Form.Item>

                        {/* program display on channel */}
                        <Form.Item
                            label="Select Channel"
                            name="channel"
                            rules={[
                                { 
                                    required: true,
                                    message: 'Have to select channel!'
                                }
                            ]}
                        >   
                            <DebounceSelect
                                value={optionValue}
                                placeholder="Select users"
                                fetchOptions={fetchUserList}
                                onChange={(newValue) => {
                                    setOptionValue(newValue);
                                }}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                        {/* submit button */}
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>

                    </Form>
                </Col>
                </Row>
            </Spin>
        </>
    )
}

export default ProgramInputs;