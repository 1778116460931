import React from 'react';
import { Link } from "react-router-dom";
import { Menu } from 'antd';
import {
    PieChartOutlined,
    CloudUploadOutlined,
    VideoCameraOutlined,
    OrderedListOutlined,
    PlayCircleOutlined,
    AppstoreAddOutlined,
    ApartmentOutlined,
    LogoutOutlined,
    PushpinOutlined,
    ProfileOutlined,
    FlagOutlined
} from '@ant-design/icons';
import { clearAdminCookies } from '../helpers/Cookies';

class RouterMenu extends React.Component {

    state = {
        collapsed: false,
    };

    onCollapse = (collapsed) => {
        this.setState({
          collapsed,
        });
    };

    // admin logout
	signout = (e) => {
		e.preventDefault()
		// clear admin cookies data
		clearAdminCookies('stream_adata')
		// redirect to login page
		window.location.href = "/"
	}

    render() {
        
        return (
            <Menu 
                mode="inline"
                theme="dark"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                style={{
                    height: '100%',
                    borderRight: 0,
                }}>
                    
                    {/* home page */}
                    <Menu.Item key="1">
                        <PieChartOutlined />
                        <span>Dashboard</span>
                        <Link to="/admin" />
                    </Menu.Item>
                    
                    {/* Video flag */}
                    <Menu.SubMenu  key="1001" title="Flag" icon={<FlagOutlined />}>
                        <Menu.Item key="101">
                            <PushpinOutlined />
                            <span>Create Flag</span>
                            <Link to="/admin/flag" />
                        </Menu.Item>
                        <Menu.Item key="102">
                            <ProfileOutlined />
                            <span>Flag List</span>
                            <Link to="/admin/flag-list" />
                        </Menu.Item>
                    </Menu.SubMenu>

                    {/* video options */}
                    <Menu.SubMenu 
                        key="1002"
                        title="Video" 
                        icon={<VideoCameraOutlined />}>
                            <Menu.Item key="2">
                                <CloudUploadOutlined />
                                <span>Upload Video</span>
                                <Link to="/admin/upload" />
                            </Menu.Item>
                            <Menu.Item key="3">
                                <OrderedListOutlined />
                                <span>Todays List</span>
                                <Link to="/admin/upload-today" />
                            </Menu.Item>
                            <Menu.Item key="31">
                                <OrderedListOutlined />
                                <span>Video List</span>
                                <Link to="/admin/upload-list" />
                            </Menu.Item>
                    </Menu.SubMenu>

                    {/* channel options */}
                    <Menu.SubMenu 
                        key="1003"
                        title="Channel" 
                        icon={<PlayCircleOutlined />}>
                            <Menu.Item key="4">
                                <AppstoreAddOutlined />
                                <span>Add Channel</span>
                                <Link to="/admin/channel" />
                            </Menu.Item>
                            <Menu.Item key="5">
                                <OrderedListOutlined />
                                <span>Channel List</span>
                                <Link to="/admin/channel-list" />
                            </Menu.Item>
                    </Menu.SubMenu>

                    {/* program options */}
                    <Menu.SubMenu 
                        key="1004"
                        title="Program" 
                        icon={<ApartmentOutlined />}>
                            <Menu.Item key="6">
                                <AppstoreAddOutlined />
                                <span>Add Program</span>
                                <Link to="/admin/program" />
                            </Menu.Item>
                            <Menu.Item key="7">
                                <OrderedListOutlined />
                                <span>Program List</span>
                                <Link to="/admin/program-list" />
                            </Menu.Item>
                    </Menu.SubMenu>

                    {/* logout */}
                    <Menu.Item key="8">
                        <LogoutOutlined />
                        <span onClick={this.signout}>
                            Logout
                        </span>
                    </Menu.Item>
            </Menu>
        );
    }
}


export default RouterMenu;