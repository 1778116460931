import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Table, Tag, Modal, Spin, Row, Col, Button, message,Input, Space } from 'antd';
import { AppstoreAddOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { getVideoList, videoDelete } from "../../../api/request/Video";
import { ReadableDateTime } from "../../../helpers/DateTime";
import Bradecrumb from "../../components/bradecrumb/Bradecrumb";
import Divider from "../../components/divider/Divider";
import { Link } from "react-router-dom";
import Highlighter from 'react-highlight-words';

/**
 * display video list
 * 
 * @VideoList
 * @created_at  16th May 2022
 * @created_by  Muhammad Hasan
 */
function VideoList(){
    // state of loading indication
    const [loading, setLoading] = React.useState(false)

    const [searchText, setSearchText] = React.useState('');
    const [searchedColumn, setSearchedColumn] = React.useState('');
    const searchInput = React.useRef(null);
    
    // video list
    const [channelData, setChannelData] = React.useState([])
    
    // filter state
    const [filteredInfo, setFilteredInfo] = React.useState({});

    // sort state
    const [sortedInfo, setSortedInfo] = React.useState({});

    const [copied, setCopied] = React.useState("")

    const { confirm } = Modal
    
    const key = 'updatable'

    // request for delete channel
    const deleteChannel = async (id) => {
        confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: '',

            async onOk() {
                // show loading indication
                message.loading({ content: 'Video Deleting...', key})
                
                // display page loading
                setLoading(false)

                // make an api request to delete video, from the server
                const result = await videoDelete(id)

                if(result){
                    setTimeout(() => {
                        if(result.status === 'success'){
                            // display success message
                            message.success({content: result.message, key, duration: 1})
                            
                            // remove channel from the list
                            const updated_list = channelData.filter((data) => {
                                if(data.vid != id){
                                    return data
                                }
                            })
                            
                            // update channel list
                            setChannelData(updated_list)
                        } 
                        else {
                            // display error message
                            message.error({content: result.message, key, duration: 1})
                        }
                        // hide page loading
                        setLoading(false)
                    }, 1000)
                }
            },

            onCancel() {},
        });
    }

    // request for update video
    const updateVideo = async (id) => {
        alert('Update Upcomming..')
        // const result = await programUpdate(id)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
      };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 8}} >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8,display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined}} />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },  
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    autoEscape
                    highlightStyle={{backgroundColor: '#ffc069',padding: 0}}
                    searchWords={[searchText]}
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : ( text ),
    })

    React.useEffect(() => {

        const get_video_list = async () => {
            // start page loading
            setLoading(true)

            // fetch video list
            const result = await getVideoList({page: 1})
            
            if(result) {
                // stop page lading indication
                setTimeout(() => {
                    setLoading(false)
                }, 800)
                
                // set video list
                setChannelData(result.data)
            }
        }

        get_video_list()
    }, [])

    const columns = [
        {
          title: 'Id',
          dataIndex: 'vid',
          key: 'vid',
          width: 50,
        },
        {
            title: 'Title',
            dataIndex: 'video_title',
            key: 'video_title',
            render: (value, data) => (
                <>
                    <a href={`${process.env.REACT_APP_STREAM_SERVER}details/${data._id}`} target="_blank">
                        {value}
                    </a>
                </>
            ),
            width: 150,
            ...getColumnSearchProps('video_title'),
        },
        {
          title: 'Created At',
          dataIndex: 'created_at',
          key: 'created_at',
          render: value => <span>{ReadableDateTime(value)}</span>,
          width: 150,
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, data) => (
            <span>
                {/* Remove video */}
                {/* <Tag 
                    color="red"
                    key={`remove_${data.vid}`}
                    onClick={()=>{deleteChannel(data.vid)}}>
                    Delete
                </Tag> */}

                <Tag 
                    color="green" 
                    key={`edit_${data.vid}`} 
                    onClick={()=>{updateVideo(data.vid)}}>
                    Update
                </Tag>

                {/* copy video url */}
                <Tag
                    color="gold" 
                    key={`copy_${data.vid}`}>
                    <CopyToClipboard 
                        text={`${process.env.REACT_APP_STREAM_SERVER}details/${data._id}`} 
                        onCopy={() => {
                            setCopied(true)
                            message.success("Video URL Copied")
                        }}>
                        <span>Copy url</span>
                    </CopyToClipboard>
                </Tag>
            </span>
          ),
          width: 150,
        }
    ];

    return(
        <>
            <Spin spinning={loading} delay={500}>

                <Bradecrumb item="upload" />
                
                <Divider 
                    type="" 
                    text="Todays Uploaded Video List" 
                    position="left" 
                />

                <Row>
                    <Col span="24" offset="0">
                        <Link to={"/admin/upload"}>
                            <Button type="primary" icon={<AppstoreAddOutlined/>}>
                                Add Video
                            </Button>
                        </Link>
                    </Col>
                </Row>
                
                <Row>
                    <Col span={24} offset={0}>
                        <Table 
                            columns={columns} 
                            dataSource={channelData} 
                            onChange={handleChange}/>
                    </Col>
                </Row>

            </Spin>
        </>
    )
}

export default VideoList;