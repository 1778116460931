/**
 * print console log with selected format
 * 
 * @log
 * @param {string} type - response type
 * @param {string} msg - response message
 * @returns {string} 
 * @created_at  2nd September 2021
 * @created_by  Muhammad Hasan
 */
exports.log = (type='', msg='') => {

    switch(type){
        case 'success':
            console.log(`✅  ${msg}`)
            break
        case 'error':
            console.error(`⛔  ${msg}`)
            break
        case 'info':
            console.log(`📌  ${msg}`)
            break
        default:
            console.log(`❕  ${msg}`)
    }

    return true
}