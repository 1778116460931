/**
 * include dependency of axios request
 * 
 * @AxiosInstance
 * @created_at  07th May 2022
 * @created_by  Muhammad Hasan
 */
 import AxiosInstance from './Index'

 /**
  * include dependency of API request endpoint
  * 
  * @EndPoints
  * @created_at  07th May 2022
  * @created_by  Muhammad Hasan
  */
 import EndPoints from '../endpoints/Endpoints'
 
 /**
  * include dependency of API request endpoint
  * 
  * @log
  * @created_at  07th May 2022
  * @created_by  Muhammad Hasan
  */
 import { log } from '../../helpers/ConsoleLog'
 
/**
 * include dependency of API request response
 * 
 * @Response
 * @created_at  07th May 2022
 * @created_by  Muhammad Hasan
 */
import { SuccessResponse, ErrorResponse } from '../../helpers/Response'
  
/**
 * create a program on tv channel
 * 
 * @programCreate
 * @created_at  07th May 2022
 * @created_by  Muhammad Hasan
 */
export const programCreate = async (req) => {
    // init with error message
    const error_response = {
        status: 'fail',
        msg: 'Something gone wrong.',
        data: []
    }
 
    try {

        const form_data = {
            channel_id: req.channel.value,
            channel_name: req.channel.label,
            program: req.program_name,
            on_air: req.on_air._d,
            duration_hour: req.hour,
            duration_min: req.min,
            duration_seconds: req.seconds,
        }

        // init params object
        const params = new URLSearchParams()
         
        // add params data
        params.append('form_data', JSON.stringify(form_data))
 
        const data = await AxiosInstance.post(EndPoints.program_create, params)
            .then(function (data) {
                return SuccessResponse(data)
            })
            .catch(function (err) {
                log('error', `Error due to : ${err.message}`)
                return ErrorResponse(err)
            })
        return data
    } catch(err) {
        return error_response
    }
}
  
/**
 * fetch program list
 * 
 * @programList
 * @created_at  07th May 2022
 * @created_by  Muhammad Hasan
 */
export const programList = async () => {
    try {
        const data = await AxiosInstance.post(EndPoints.program_list)
                    .then((data) => {
                        return SuccessResponse(data)
                    })
                    .catch((err) => {
                        log('error', `Error due to : ${err.message}`)
                        return ErrorResponse(err)
                    })
        return data
    } catch (err) {

    }
}

/**
 * delete channel by channel id
 * 
 * @programDelete
 * @created_at  07th May 2022
 * @created_by  Muhammad Hasan
 */
export const programDelete = async (id) => {
    try {
        // init params object
        const params = new URLSearchParams()
        
        // set channel id / cid
        params.append('cid', id)

        const data = await AxiosInstance.post(EndPoints.channel_delete, params)
                    .then((data) => {
                        return SuccessResponse(data)
                    })
                    .catch((err) => {
                        log('error', `Error occured on channelDelete, due to: ${err}`)
                        return ErrorResponse(err)
                    })
        return data
    } catch (err) {
        log('error', `Error occured on channelDelete, due to: ${err}`)
        return false
    }
}

/**
 * update channel info
 * 
 * @programUpdate
 * @created_at  07th May 2022
 * @created_by  Muhammad Hasan
 */
export const programUpdate = async (id) => {
    try {
        console.log(id)
    } catch (err) {
        log('error', `Error occured on channelUpdate, due to: ${err}`)
        return false
    }
}