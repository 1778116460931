import React from "react"

function Dashboard(){
    return(
        <>
            Message from Dashboard page
        </>
    )
}

export default Dashboard