import React from "react"

function Meseros(){
    return(
        <>
            Message from Meseros page
        </>
    )
}

export default Meseros