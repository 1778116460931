import React from 'react';
import { 
    Form, 
    Input, 
    Button, 
    Row, 
    Col,
    Divider,
    Upload, 
    Spin,
    message,
    Select
} from 'antd';
import {
    UploadOutlined 
} from '@ant-design/icons';
import { 
    videoCreate
} from "../../../api/request/Video";
import { flagSearch } from "../../../api/request/Flag";
import Bradecrumb from "../../components/bradecrumb/Bradecrumb";
import debounce from 'lodash/debounce';


/**
 * added a program to the database
 * 
 * @Video
 * @created_at  16th May 2022
 * @created_by  Muhammad Hasan
 */
function Video(){
    // state of loading indication
    const [loading, setLoading] = React.useState(false)

    const [optionValue, setOptionValue] = React.useState([]);

    // single video path
    const [videoPath, setVideoPath] = React.useState('')

    const [options, setOptions] = React.useState([]);

    const [fetching, setFetching] = React.useState(false);

    const fetchRef = React.useRef(0);

    // multi video path

    const onFinish = async (values) => {
        // start page loading
        setLoading(false)
        
        // store video files details
        let video_files = []

        // loop throw uploaded video
        values.video.fileList.map((data, index) => {
            let file = {
                name: data.name,
                path: data.response.data
            }
            video_files.push(file)
        })
        
        // generate video form data
        const form_data = {
            video_flag: values.flag.value,
            video_deatils: JSON.stringify(video_files),
            thumbPath: '',
            type: "flag"
        }

        // api request for upload 
        const result = await videoCreate(form_data)

        // if gets any wrong
        if(result.status === 'success'){
            setTimeout(() => {
                setLoading(false)
                message.success(result.message)
            }, 1000)
        } else {
            setTimeout(() => {
                setLoading(false)
                message.error(result.message);
            }, 1000)
        }
    };
    

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // select video flag list searchable
    function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
        const [fetching, setFetching] = React.useState(false);
        const [options, setOptions] = React.useState([]);
        const fetchRef = React.useRef(0);

        const debounceFetcher = React.useMemo(() => {
            const loadOptions = (value) => {
                fetchRef.current += 1;
                const fetchId = fetchRef.current;
                setOptions([]);
                setFetching(true);
                fetchOptions(value).then((newOptions) => {
                    if (fetchId !== fetchRef.current) {
                        // for fetch callback order
                        return;
                    }
                    setOptions(newOptions);
                    setFetching(false);
                });
            };
            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
        <Select
            showSearch
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
        );
    }

    // search video flag by flag name
    async function fetchFlagList(search_q) {
        return await flagSearch(search_q)
                .then((res) => res.data)
                .then((body) => 
                    body.map((list) => ({
                        label: list.flag_name,
                        value: list.flag_id
                    }))
                )
    }

    // upload video to server
    const props = {
        maxCount: 20,
        name: 'vedio',
        action: `${process.env.REACT_APP_RESOURCE_SERVER}upload/stream/video`,
        headers: {
          authorization: '',
        },
        async onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log('File uploading....', info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setVideoPath(info.file.response.data);
                console.log(info.file.response.data);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        progress: {
            strokeWidth: 3,
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    return(
        <>
            <Spin spinning={loading} delay={500}>
                
                <Bradecrumb item="upload"/>
                
                <Divider orientation="left">Add New Video</Divider>

                <Row>
                    <Col span={12} offset={0}>
                        <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ remember: true }}    
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            
                            {/* program display on flag */}
                            <Form.Item
                                label="Select Flag"
                                name="flag"
                                rules={[
                                    { 
                                        required: true,
                                        message: 'Have to select video flag!'
                                    }
                                ]}
                            >   
                                <DebounceSelect
                                    value={optionValue}
                                    placeholder="Select Flag"
                                    fetchOptions={fetchFlagList}
                                    onChange={(newValue) => {
                                        setOptionValue(newValue);
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            
                            {/* video field */}
                            <Form.Item
                                label="Video"
                                name="video"
                                rules={[
                                    {
                                        required: true,
                                        message: "Video field can't be empty !",
                                    },
                                ]}
                            >
                                <Upload {...props}>
                                        <Button icon={<UploadOutlined />}>
                                            Upload video
                                        </Button>
                                </Upload>
                            </Form.Item>

                            {/* submit button */}
                            <Form.Item wrapperCol={{offset: 8, span: 16,}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>

            </Spin>
        </>
    )
}

export default Video;