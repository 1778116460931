/**
 * all text used on flag section
 * 
 * @created_at  30th May 2022
 * @created_by  Muhammad Hasan
 */
export default {
    // for flag breadcrumb 
    breadcrumb : {
        list: "Flag List",
        flag: "Flag"
    },

    // for flag add page
    add_page :  {
        title: "Add New Flag",
        label_flag: "Write Flag Name",
    },

    // for flag list page
    list_page: {
        title: "Flag List",
        add_btn: "Add New Flag"
    },

    // for flag forms
    error: { 
        empty_flag: "Flag name can't be empty.",
        empty_date: "Date must be valid",
        unique_flag: "Flag name must be unique.",
    }

}