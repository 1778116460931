const EndPoints = {
    // General api endpoint
    admin_login: 'streaming/admin/login',

    // flag api
    flag_create: 'streaming/flag/create',
    flag_list: 'streaming/flag/list',
    flag_search: 'streaming/flag/search',
    flag_details: 'streaming/flag/detail',
    flag_video_remove: 'streaming/flag/remove-video',

    // channel api
    channel_create: 'streaming/channel/create',
    channel_list: 'streaming/channel/list',
    channel_update: 'streaming/channel/update',
    channel_delete: 'streaming/channel/delete',
    channel_search: 'streaming/channel/search',
    channel_pagination: 'streaming/channel/pagination',

    // program api
    program_create: 'streaming/program/create',
    program_list: 'streaming/program/list',
    program_update: 'streaming/program/update',
    program_delete: 'streaming/program/delete',
    program_search: 'streaming/program/search',
    program_pagination: 'streaming/program/pagination',

    // video api
    // video_get_by_type_id: 'streaming/video/find-by-type-id',
    // video_update: 'streaming/video/update',
    // video_delete: 'streaming/video/delete',
    // video_search: 'streaming/video/search',
    // video_pagination: 'streaming/video/pagination',

    // streaming api
    stream_upload: 'upload/stream/video',
    video_create: 'streaming/video/create',
    video_list: 'streaming/video/list',
    video_delete: 'streaming/video/delete',
}
  
export default EndPoints
  