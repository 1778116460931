import React from 'react';
import { 
    Table,
    Tag,
    Modal,
    Spin,
    Row,
    Col,
    Button,
    message, 
    Input, 
    Space} from 'antd';
import { 
    AppstoreAddOutlined, 
    SearchOutlined,
    ExclamationCircleOutlined  } from '@ant-design/icons';
import { 
    flagList,
    getFlagDetails,
    removeVideoFromFlag
} from "../../../api/request/Flag";
import text from "../../../helpers/constant/Flag";
import { ReadableDateTime } from "../../../helpers/DateTime";
import Bradecrumb from "../../components/bradecrumb/Bradecrumb";
import Divider from "../../components/divider/Divider";
import { Link } from "react-router-dom";
import Highlighter from 'react-highlight-words';
import {CopyToClipboard} from 'react-copy-to-clipboard';


/**
 * recent flag list
 * 
 * @FlaglList
 * @created_at  30th May 2022
 * @created_by  Muhammad Hasan
 */
function FlaglList(){
    // state of loading indication
    const [loading, setLoading] = React.useState(false)
    // flag search text
    const [searchText, setSearchText] = React.useState('');
    // set flag column for search
    const [searchedColumn, setSearchedColumn] = React.useState('');
    // 
    const searchInput = React.useRef(null);
    // filter state
    const [filteredInfo, setFilteredInfo] = React.useState({});
    // sort state
    const [sortedInfo, setSortedInfo] = React.useState({});
    // channel list
    const [channelData, setChannelData] = React.useState([]);
    // state for flag details modal
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    // state for flag video urls
    const [videoURl, setVideoURL] = React.useState([]);
    // state for video url copy indicator
    const [copied, setCopied] = React.useState("")
    // state for hold flag id
    const [flagId, setFlagId] = React.useState(0)
    // state for remove modal
    const { confirm } = Modal
    // search flag by name
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    
    // resert search queries
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    // set searchable option
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 8}} >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8,display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined}} />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },  
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    autoEscape
                    highlightStyle={{backgroundColor: '#ffc069',padding: 0}}
                    searchWords={[searchText]}
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : ( text ),
    })

    React.useEffect(() => {
        // fetch flag list
        const fetchChannelList = async () => {
            // start page loading
            setLoading(true)

            // fetch channel list
            const result = await flagList()
            
            if(result) {
                // stop page lading indication
                setTimeout(() => {
                    setLoading(false)
                }, 800)
                
                // set channel list
                setChannelData(result.data)
            }
        }
        fetchChannelList()
    }, [])

    // 
    const flagDetails = async (id) => {
        // open modal
        showModal()

        // fetch flag details
        const flag_details = await getFlagDetails(id)
        
        // if flag url exists
        if(flag_details.data?.['video_url']){
            const video_url = JSON.parse(flag_details.data?.['video_url'])
            // set flag video details
            setVideoURL(video_url)
            setFlagId(id)    
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleOk = () => {
        setIsModalVisible(false);
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // remove video from the server
    const removeVideo = (url) => {
        confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            async onOk() {
                // show loading indication
                message.loading({ content: 'Video Deleting...', flagId})
                
                // display page loading
                setLoading(true)

                // make an api request to delete video, from the server
                const result = await removeVideoFromFlag({
                    url: url.id, 
                    flag: flagId
                })

                if(result) {
                    setTimeout(() => {
                        if(result.status === 'success'){
                            // display success message
                            message.success({
                                content: result.message, 
                                flagId, 
                                duration: 1
                            })
                            
                            // remove channel from the list
                            const updated_list = videoURl.filter((data) => {
                                if(data.id != url.id){
                                    return data
                                }
                            })
                            
                            // update channel list
                            setVideoURL(updated_list)
                        } 
                        else {
                            // display error message
                            message.error({
                                content: result.message, 
                                flagId, 
                                duration: 1
                            })
                        }
                        // hide page loading
                        setLoading(false)
                    }, 1000)
                }

            },
            onCancel() {}
        })
    }

    // table column for flag list
    const columns = [
        {
          title: 'Flag Id',
          dataIndex: 'flag_id',
          key: 'flag_id',
          width: 50,
        },
        {
            title: 'Flag Name',
            dataIndex: 'flag_name',
            key: 'flag_name',
            width: 150,
            ...getColumnSearchProps('flag_name'),
        },
        // {
        //     title: 'Total Files',
        //     dataIndex: 'total_video',
        //     key: 'total_video',
        //     width: 25,
        // },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: value => <span>{ReadableDateTime(value)}</span>,
            width: 150,
        },
        {
            title: 'Action',  
            key: 'action',
            render: (text, data) => (
                <span>
                    <Tag 
                        color="blue" 
                        key={`details_${data.flag_id}`} 
                        onClick={()=>{flagDetails(data.flag_id)}}
                    >
                        Details
                    </Tag>
                    <Tag 
                        color="green" 
                        key={`update_${data.flag_id}`} 
                        // onClick={()=>{updateChannel(data.cid)}}
                    >
                        Update
                    </Tag>
                </span>
            ),
            width: 150,
        }
    ];

    // table column for video list of individual flag
    const flag_details_column = [
        {
            title: "Name",
            dataIndex: "title"
        },
        {
            title: "Action",
            render: (text, data) => (
                <span>
                    <Tag 
                        color="red" 
                        key={`remove_${data.id}`} 
                        onClick={() => {removeVideo(data)}}
                    >
                        Remove
                    </Tag>

                    {/* copy video url */}
                    <Tag 
                        color="green" 
                        key={`copy_${data.id}`} 
                    >
                        <CopyToClipboard 
                            text={`${process.env.REACT_APP_STREAM_SERVER}details/${data.id}`} 
                            onCopy={() => {
                                setCopied(true)
                                message.success("Video URL Copied")
                            }}>
                            <span>Copy url</span>
                        </CopyToClipboard>
                    </Tag>
                    
                    {/* visit video url */}
                    <Tag
                        color="gold" 
                        key={`view${data.id}`} 
                        onClick={() => {
                            window.open(process.env.REACT_APP_STREAM_SERVER+'details/'+data.id)
                        }}
                    >
                        View
                    </Tag>
                </span>
            ),
        }
    ] 

    return(
        <>
            <Spin spinning={loading} delay={500}>

                <Bradecrumb item="channel"/>
                
                <Divider 
                    type="" 
                    text={text.list_page.title} 
                    position="left" 
                />

                <Row>
                    <Col span="24" offset="0">
                        <Link to={"/admin/flag"}>
                            <Button type="primary" icon={<AppstoreAddOutlined/>}>
                                {text.list_page.add_btn}
                            </Button>
                        </Link>
                    </Col>
                </Row>
                
                <Row>
                    <Col span={24} offset={0}>
                        <Table columns={columns} dataSource={channelData} />
                    </Col>
                </Row>

                <Modal 
                    title="Video List" 
                    visible={isModalVisible} 
                    onOk={handleOk} 
                    onCancel={handleCancel}
                >
                    <Table columns={flag_details_column} dataSource={videoURl} />
                </Modal>    

            </Spin>
        </>
    )
}

export default FlaglList;