/**
 * simplify api request, error response
 *
 * @ErrorResponse
 * @created_by  Muhammad Hasan
 * @created_at  19th August 2021
 */
export const ErrorResponse = (error) => {
    const response = {}

    let result = error.response

    if (typeof error === 'object') {
      if (typeof error.response.data === 'object') {
        response.code = result.status
        response.data = typeof result.data.data === 'object' ? result.data.data : null
        response.message = typeof result.data.msg === 'string' ? result.data.msg : ''
        response.status = typeof result.data.status === 'string' ? result.data.status : null
      }
    }

    return response
}
  
/**
 * simplify api request, success response
 *
 * @SuccessResponse
 * @created_by  Muhammad Hasan
 * @created_at  19th August 2021
 */
export const SuccessResponse = (res) => {
    const response = {}

    let result = res.data

    if (typeof result === 'object') {
      response.code = res.status
      response.data = typeof result.data === 'object' ? result.data : null
      response.status = typeof result.status === 'string' ? result.status : null
      response.message = typeof result.msg === 'string' ? result.msg : ''
    }
    
    return response
}
  