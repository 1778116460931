import React from 'react';
import { useParams } from "react-router-dom";
import { Upload, Timeline, Input, Button, Row, Col, message, Spin, Modal} from 'antd';
import {
    videoCreate,
    videoGetByTypeId
 } from "../../../api/request/Video";
import Bradecrumb from "../../components/bradecrumb/Bradecrumb";
import Divider from "../../components/divider/Divider";
import {
    UploadOutlined,
    SaveFilled,
} from '@ant-design/icons';
import ReactPlayer from 'react-player';
import { ReadableDateTime } from "../../../helpers/DateTime";

/**
 * manage program deatils with video, auido media
 *
 * @ProgramManage
 * @created_at  07th May 2022
 * @created_by  Muhammad Hasan
 */
function ProgramManage() {
    // state of loading indication
    const [loading, setLoading] = React.useState(false);
    // video path
    const [videoPath, setVideoPath] = React.useState('');
    // thumbnail path
    const [thumbPath, setThumbPath] = React.useState('');
    // video list for display timeline
    const [timeline, setTimelineData] = React.useState([]);
    // program pid
    let { id } = useParams();

    const [isModalVisible, setIsModalVisible] = React.useState(false);


    // form submit
    const submitHandle = async (values) => {
        // start page loading
        setLoading(true);

        // init form data
        const data = {
            videoPath,
            thumbPath,
            id,
            type: 'program'
        }

        // request to store upload data
        const result = await videoCreate(data);

        // if gets any wrong
        if(result.status === 'success'){
            message.success(result.message);
            setLoading(false);
            setTimeout(() => {
                window.location.reload()
            }, 800)
        } else {
            message.error(result.message);
            setLoading(false);
        }
    };

    // upload video to server
    const props = {
        maxCount: 1,
        name: 'vedio',
        action: 'http://localhost:2121/upload/stream/video',
        headers: {
          authorization: 'authorization-text',
        },
        async onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log('File uploading....');
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setVideoPath(info.file.response.data);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        progress: {
            strokeWidth: 3,
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    // open video with modal
    const showModal = (path) => {
        setIsModalVisible(true);
        // set video path which need to open
        setVideoPath(path)
    };

    // close video modal, when ok button press
    const handleOk = () => {
        setIsModalVisible(false);
    };

    // close video modal, when cancel button press
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    React.useEffect(() => {
        const fetch_existing_videos = async () => {
            // start page loading
            setLoading(true)

            // fetch channel list
            const result = await videoGetByTypeId(id)

            if(result) {
                // stop page lading indication
                setTimeout(() => {
                    setLoading(false)
                }, 800)

                // set channel list
                setTimelineData(result.data)
            }
        }
        fetch_existing_videos()
    }, []);

    return(
        <>
            <Spin spinning={loading} delay={500}>

                <Bradecrumb />

                <Divider type="" text="" position="left" />

                <Row>
                    <Col span={8}>
                        <Divider type="" text="Upload Section" position="left" />
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>
                                upload video
                            </Button>
                        </Upload>
                        <br/>
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>
                                upload thumbnail
                            </Button>
                        </Upload>
                        <br/>
                        <Button icon={<SaveFilled />} onClick={submitHandle}>
                            Save Media
                        </Button>
                    </Col>

                    <Col span={12} offset={4}>
                        <Divider type="" text="Uploaded File Timeline" position="left" />
                        <Timeline>
                            {timeline.map(value =>
                                <Timeline.Item color="green">
                                    Added this video on -
                                    {ReadableDateTime(value.created_at)} - <u onClick={() => showModal(value.video_path)}>show preview</u>
                                </Timeline.Item>
                            )}
                        </Timeline>

                        <Modal title="Video" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                            <ReactPlayer
                                url={`${process.env.REACT_APP_RESOURCE_SERVER}${videoPath}`}
                                controls = {true}
                                autoPlay={true}
                                width='100%'
                                height='50%'
                            />
                        </Modal>


                    </Col>
                </Row>
            </Spin>
        </>
    )
}

export default ProgramManage;
