/**
 * include dependency of axios request
 * 
 * @AxiosInstance
 * @created_at  18th May 2022
 * @created_by  Muhammad Hasan
 */
import AxiosInstance from './Index'

/**
 * include dependency of API request endpoint
 * 
 * @EndPoints
 * @created_at  18th May 2022
 * @created_by  Muhammad Hasan
 */
import EndPoints from '../endpoints/Endpoints'
 
/**
 * include dependency of API request endpoint
 * 
 * @log
 * @created_at  18th May 2022
 * @created_by  Muhammad Hasan
 */
import { log } from '../../helpers/ConsoleLog'
 
/**
 * include dependency of API request response
 * 
 * @Response
 * @created_at  18th May 2022
 * @created_by  Muhammad Hasan
 */
import { SuccessResponse, ErrorResponse } from '../../helpers/Response'
  
/**
 * api call for login
 * 
 * @programCreate
 * @created_at  18th May 2022
 * @created_by  Muhammad Hasan
 */
export const login = async (req) => {
    // init with error message
    const error_response = {
        status: 'fail',
        msg: 'Something gone wrong.',
        data: []
    }
 
    try {
        // init params object
        const params = new URLSearchParams();
         
        // add params data
        params.append('email', req.email);
        params.append('password', req.password);
 
        const data = await AxiosInstance.post(EndPoints.admin_login, params)
            .then(function (data) {
                return SuccessResponse(data)
            })
            .catch(function (err) {
                log('error', `Error due to : ${err.message}`)
                return ErrorResponse(err)
            })
        return data
    } catch(err) {
        return error_response
    }
}
