import React from 'react'
import { Form, Input, Button, Row, Col, message, Spin} from 'antd';
import { channelCreate } from "../../../api/request/Channel";
import Bradecrumb from "../../components/bradecrumb/Bradecrumb";
import Divider from "../../components/divider/Divider";

/**
 * added a channel to the database
 * 
 * @ChannelInputs
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
function ChannelInputs(){
    // state of loading indication
    const [loading, setLoading] = React.useState(false)

    // form submit
    const submitHandle = async (values) => {
        // start page loading
        setLoading(true)

        // request to create a channel
        const result = await channelCreate(values)
        
        // if gets any wrong
        if(result.status === 'success'){
            message.success(result.message)
            setLoading(false)
        } else {
            message.error(result.message);
            setLoading(false)
        }
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return(
        <>
            <Spin spinning={loading} delay={300}>

                <Bradecrumb item="channel" />
                
                <Divider type="" text="Add New Channel" position="left" />

                <Row>
                    <Col span={12} offset={0}>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            initialValues={{
                                remember: true,
                            }}    
                            onFinish={submitHandle}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off">

                            <Form.Item
                                label="Channel Name"
                                name="channel_name"
                                rules={[
                                {
                                    required: true,
                                    message: "Channel name can't be empty",
                                },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Create Channel
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>

            </Spin>
        </>
    )
}

export default ChannelInputs;