import React from 'react';
import { Route, Routes } from "react-router-dom";

import Dashboard from '../pages/containers/Dashboard/Dashboard';
import Meseros from '../pages/containers/Meseros/Meseros';
import Channel from '../pages/containers/Channel/Channel';
import ChannelList from '../pages/containers/Channel/ChannelList';
import Program from '../pages/containers/Program/Program';
import ProgramList from '../pages/containers/Program/ProgramList';
import ProgramManage from '../pages/containers/Program/ProgramManage';
import Video from '../pages/containers/Video/Video';
import VideoList from '../pages/containers/Video/VideoList';
import FlagAdd from '../pages/containers/Flag/FlagAdd';
import FlaglList from '../pages/containers/Flag/FlagList';

class RouterMenu extends React.Component {

    state = {
        collapsed: false,
    };

    onCollapse = (collapsed) => {
        this.setState({
          collapsed,
        });
    };

    render() {
        
        return (
            <Routes>
                <Route exact path="/admin" element={<Dashboard />} />
                <Route path="/meseros" element={<Meseros />} />

                {/* routes for flag */}
                <Route path="/admin/flag" element={<FlagAdd />} />
                <Route path="/admin/flag-list" element={<FlaglList />} />
                <Route path="/admin/flag-search" element={<Video />} />

                {/* routes for video */}
                <Route path="/admin/upload" element={<Video />} />
                <Route path="/admin/upload-today" element={<VideoList />} />
                
                {/* routes for program */}
                <Route path="/admin/program" element={<Program />} />
                <Route path="/admin/program-list" element={<ProgramList />} />
                <Route path="/admin/program-manage/:id" element={<ProgramManage />} />

                {/* routes for channel */}
                <Route path="/admin/channel" element={<Channel />} />
                <Route path="/admin/channel-list" element={<ChannelList />} />
            </Routes>
        );
    }
}


export default RouterMenu;