import { getAdminToken } from '../../helpers/Cookies'

/**
 * add dependencies
 * @object
 * @name axios
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
const axios = require('axios')

/**
 * request instance of axios request object
 * 
 * @requestInstance
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
const requestInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: ( getAdminToken() ) ? getAdminToken() : ''
  },
})

export default requestInstance
