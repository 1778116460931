import React from 'react';
import { Table,  Tag, Modal, Spin, Row, Col, Button, message } from 'antd';
import { AppstoreAddOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import { 
    channelList, 
    channelDelete,
    channelUpdate
} from "../../../api/request/Channel";
import { ReadableDateTime } from "../../../helpers/DateTime";
import Bradecrumb from "../../components/bradecrumb/Bradecrumb";
import Divider from "../../components/divider/Divider";
import { Link } from "react-router-dom"

/**
 * added a channel to the database
 * 
 * @ChannelInputs
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
function ChannelInputs(){
    // state of loading indication
    const [loading, setLoading] = React.useState(false)
    
    // channel list
    const [channelData, setChannelData] = React.useState([])
    
    const { confirm } = Modal
    
    const key = 'updatable'

    // request for delete channel
    const deleteChannel = async (id) => {
        confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            async onOk() {
                // show loading indication
                message.loading({ content: 'Channel Deleting...', key})
                
                // display page loading
                setLoading(true)

                // make an api request to delete channel
                const result = await channelDelete(id)

                if(result){
                    setTimeout(() => {
                        if(result.status === 'success'){
                            // display success message
                            message.success({content: result.message, key, duration: 2})
                            
                            // remove channel from the list
                            const updated_list = channelData.filter((data) => {
                                if(data.cid != id){
                                    return data
                                }
                            })
                            
                            // update channel list
                            setChannelData(updated_list)
                        } 
                        else {
                            // display error message
                            message.error({content: result.message, key, duration: 2})
                        }
                        // hide page loading
                        setLoading(false)
                    }, 1000)
                }
            },
            onCancel() {},
        });
    }

    // request for edit channel
    const updateChannel = async (id) => {
        const result = await channelUpdate(id)
    }

    React.useEffect(() => {
        const fetchChannelList = async () => {
            // start page loading
            setLoading(true)

            // fetch channel list
            const result = await channelList()
            
            if(result) {
                // stop page lading indication
                setTimeout(() => {
                    setLoading(false)
                }, 800)
                
                // set channel list
                setChannelData(result.data)
            }
        }
        fetchChannelList()
    }, [])

    const columns = [
        {
          title: 'Id',
          dataIndex: 'cid',
          key: 'cid',
          width: 50,
        },
        {
            title: 'Channel Name',
            dataIndex: 'channel_name',
            key: 'channel_name',
            width: 150,
        },
        {
          title: 'Created At',
          dataIndex: 'created_at',
          key: 'created_at',
          render: value => <span>{ReadableDateTime(value)}</span>,
          width: 150,
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, data) => (
            <span>
                <Tag color="red" key={`remove_${data.cid}`} onClick={()=>{deleteChannel(data.cid)}}>
                    Delete
                </Tag>
                <Tag color="green" key={`edit_${data.cid}`} onClick={()=>{updateChannel(data.cid)}}>
                    Edit
                </Tag>
            </span>
          ),
          width: 150,
        }
    ];

    return(
        <>
            <Spin spinning={loading} delay={500}>

                <Bradecrumb item="channel" />
                
                <Divider type="" text="Channel List" position="left" />

                <Row>
                    <Col span="24" offset="0">
                        <Link to={"/admin/channel"}>
                            <Button type="primary" icon={<AppstoreAddOutlined/>}>
                                Add New Channel
                            </Button>
                        </Link>
                    </Col>
                </Row>
                
                <Row>
                    <Col span={24} offset={0}>
                        <Table columns={columns} dataSource={channelData} />
                    </Col>
                </Row>

            </Spin>
        </>
    )
}

export default ChannelInputs;