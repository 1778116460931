/**
 * include dependency of axios request
 * 
 * @AxiosInstance
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
import AxiosInstance from './Index'

/**
 * include dependency of API request endpoint
 * 
 * @EndPoints
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
import EndPoints from '../endpoints/Endpoints'
 
/**
 * include dependency of API request endpoint
 * 
 * @log
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
import { log } from '../../helpers/ConsoleLog'
 
/**
 * include dependency of API request response
 * 
 * @Response
 * @created_at  25th April 2022
 * @created_by  Muhammad Hasan
 */
import { SuccessResponse, ErrorResponse } from '../../helpers/Response'
  
/**
 * upload video data to db
 * 
 * @videoCreate
 * @created_at  08th may 2022
 * @created_by  Muhammad Hasan
 */
export const videoCreate = async (req) => {
    // init with error message
    const error_response = {
        status: 'fail',
        msg: 'Something gone wrong.',
        data: []
    }
    
    try {
        // init params object
        const params = new URLSearchParams()
        
        // add params data
        params.append('flag_id', req.video_flag)
        params.append('video_detail', req.video_deatils)
        params.append('type', req.type)
        params.append('id', req.video_flag)

        const data = await AxiosInstance.post(EndPoints.video_create, params)
            .then(function (data) {
                return SuccessResponse(data)
            })
            .catch(function (err) {
                log('error', `Error due to : ${err.message}`)
                return ErrorResponse(err)
            })
        return data
    } catch(err) {
        return error_response
    }
}

/**
 * get videos by using its type id 
 * 
 * @videoGetByTypeId
 * @created_at  09th may 2022
 * @created_by  Muhammad Hasan
 */
export const videoGetByTypeId = async (id) => {
    // init with error message
    const error_response = {
        status: 'fail',
        msg: 'Something gone wrong.',
        data: []
    }

    try {
        // init params object
        const params = new URLSearchParams()
        
        // add params data
        params.append('type_id', id)

        const data = await AxiosInstance.post(EndPoints.video_get_by_type_id, params)
            .then(function (data) {
                return SuccessResponse(data)
            })
            .catch(function (err) {
                log('error', `Error due to : ${err.message}`)
                return ErrorResponse(err)
            })
        return data

    } catch (err) {
        return error_response
    }
}

/**
 * upload any video to file server
 * 
 * @uploadVideo
 * @created_at  17th may 2022
 * @created_by  Muhammad Hasan
 */
export const uploadVideo = async (data) => {

}

/**
 * get video list
 * 
 * @getVideoList
 * @created_at  17th may 2022
 * @created_by  Muhammad Hasan
 */
export const getVideoList = async (req) => {
    // init with error message
    const error_response = {
        status: 'fail',
        msg: 'unable to fetch video list.',
        data: []
    }

    try {

        // init params object
        const params = new URLSearchParams()
        
        // pagination page number
        params.append('page', req.page_no)

        const data = await AxiosInstance.post(EndPoints.video_list, params)
            .then(function (data) {
                return SuccessResponse(data)
            })
            .catch(function (err) {
                log('error', `Error due to : ${err.message}`)
                return ErrorResponse(err)
            })
        return data
    } catch (err) {
        return error_response
    }
}


/**
 * delete video form the server
 * 
 * @videoDelete
 * @created_at  29th may 2022
 * @created_by  Muhammad Hasan
 */
export const videoDelete = async (id) => {
    // init with error message
    const error_response = {
        status: 'fail',
        msg: 'unable to delete video',
        data: []
    }

    try {
        // init params object
        const params = new URLSearchParams()
        
        // video id (vid)
        params.append('id', id)

        const data = await AxiosInstance.post(EndPoints.video_delete, params)
            .then(function (data) {
                return SuccessResponse(data)
            })
            .catch(function (err) {
                log('error', `Error due to : ${err.message}`)
                return ErrorResponse(err)
            })
        return data
    } catch (err) {
        return error_response
    }
}