import { Divider } from 'antd';
import PropTypes from 'prop-types';

function divider({type, text, position}){
    
    return(
        (type === 'solid') ?
            (<Divider solid />)
        : 
            (
                <Divider orientation={position}>
                    {text}
                </Divider>
            )
    )
}

divider.propTypes = {
    type: PropTypes.string.isRequired
}

export default divider